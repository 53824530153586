@import '../../../styles/index';

.Footer {
	background-color: $lightBlue;
	min-height: 500px;
	color: white;
	position: relative;

	p, a {
		font-size: 1.125rem;
		line-height: 1.8;
		font-weight: 300;
		@include breakpoint($base) {
			font-size: 0.85rem;

		}
	}
	@include breakpoint($base) {
		min-height: 100px;

	}

	a {
		text-decoration: none;
		color: white;
	}

	h5 {
		font-size: 1.25rem;
		line-height: 1.75;
		margin-bottom: .5rem;
		font-weight: 600;
		@include breakpoint($medium) {
			margin: 0;
			line-height: 1.5;
		}
		@include breakpoint($base) {
			margin: 0;
			font-size: 1rem;
			line-height: 1.5;
		}
	}
	
}

.FooterWave {
	height: 150px;
	width: 100%;
	overflow: hidden;
	position: absolute;
	top: -145px;
	z-index: 0;
	@include breakpoint($large) {
		height: 100px;
		top: -95px;
	}
	@include breakpoint($sLarge) {
		height: 100px;
		top: -95px;
	}
	@include breakpoint($medium) {
		height: 110px;
		top: -95px
	}
}

.FooterInfo {
	@include flex(row, space-between, null);
	padding: 2rem;

	> div {
		width: 25%;
		@include breakpoint($medium) {
			width: 100%;
		}
	}
	@include breakpoint($medium) {
		flex-direction: column-reverse;
		align-items: center;
		padding: 1rem;

	}
}

.MiddleInfo {
	text-align: center;
}

.Info {
	padding-top: 6.5rem;
	padding-bottom: 2rem;

	@include breakpoint($medium) {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
		padding: 1rem 0;
	}
	&:last-child {
		
		@include breakpoint($largeWide) {
			padding-left: 160px;
		}
		@include breakpoint($xLarge) {
			padding-left: 200px;
		}
		@include breakpoint($medium) {
			display: none;
		}
	}
	
}

.Logo {
	width: 210px;
	height: auto;
	margin: 0 auto 2rem;

	svg {
		width: 100%;
		height: auto;
		@include breakpoint($base) {
			width: 70%;
		}
	}
		margin: 0.2rem auto;

}

.SocialMedia {
	@include flex(row, center, null);
	margin: 2rem 0 0 0;
	a {
		display: block;
		width: 38px;
		height: 38px;
		margin: 0 .8rem;
		position: relative;
		z-index: 1;

		&::before {
			content: '';
			position: absolute;
			top: 3px;
			left: 3px;
			width: 32px;
			height: 32px;
			background-color: white;
			border-radius: 100%;
			z-index: -1;
			opacity: 0;
			transition: 0.3s;
		}
		&:hover {
			&::before {
				opacity: 1;
			}
		}
		&:nth-child(2) {
			&::before {
				left: 4px;
			}
		}

		svg {
			width: 100%;
	
			&:hover {
				g {
					&:last-child {
						path {
							fill: $lightBlue;
						}
					}
				}
			}
		}
		&:nth-child(3) {
			svg {
				&:hover {
					g {
						&:not(:last-child) {
							path {
								fill: $lightBlue;
							}
						}
					}
				}
			}
		}
		
	}
	@include breakpoint($medium) {
		margin: .2rem auto;
		justify-content: center;
		margin: 1rem;
	}
	@include breakpoint($midSmall) {
		justify-content: space-around;
	}
}

.Rights {
	width: 100%;
	text-align: center;
	line-height: 1;
	padding: 1.5rem 0;
	position: relative;

	p {
		font-size: .875rem;
	}

	&:before {
		content: '';
		width: 110%;
		height: 1px;
		position: absolute;
		left: -5%;
		top: 0;
		background-color: white;
	}
}

