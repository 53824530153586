@import url('https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700&display=swap');
@import './styleReset';

* {
	font-family: -apple-system, Hind, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: -apple-system, Hind, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	max-width: 2440px;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

.App {
	text-align: center;
	overflow-x: hidden;
	max-width: 2000px;
	margin: 0 auto;
}

.MainContent {
	width: 100%;
	//min-height: 100vh;
}

.MainWrapper {
	width: 100%;
	max-width: 1360px;
	margin: 0 auto;
	position: relative;
}

.SmallWrap {
	width: 90%;
	max-width: calc(1100 + 2rem);
	padding: 0 1rem;
	margin: 0 auto;
	
}

.TextStyles {
	color: #1c1f20;

	>h3 {
		font-size: 2.2rem;
		//line-height: 3.75rem;
		line-height: 1.6;
		font-weight: 700;
		margin: 1rem 0;
	}

	>p {
		font-size: 1.1rem;
		line-height: 1.6;
		font-weight: 300;
		text-align: justify;
	}
}

button, input, textarea {
	&:focus {
		outline: none;
	}
}

button {
	cursor: pointer;
}
