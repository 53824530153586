$lightBlue: #0f82b7;
$mediumBlue: #1484b8; 
$smoothBlue: rgba(15, 130, 183, 0.79);
$lightGray: #ebebeb;
$shadowDark: rgba(0, 0, 0, 0.1);
$dark: #1c1f20;
$smoothDark: rgba(0, 0, 0, 0.8);
$lightGray: #cccccc;
$smoothGray: #a7a7a7;
$whiteSmoke: #f8f8f8;
$shakeSpeare: #45a5ce; 
$shark: #1e2122;
$whiteSnake: #dedcde;
$silver: #bcbcbc;
$grey: #868686;
$iceGrey: #d7d7d7;
$culturedPearl: #f7f7f7;
$darkGray: #969696;
$lightDarkGray: #848484;
$cyan: #0285bc;
$Gray52: #858585;
$ebony: #282c34;
$turquoiseBlue: #61dafb;