@mixin border-radius-shadow {
    border-radius: 25px;
	box-shadow: -1.312px 1.509px 20px rgba(0,0,0,0.15);
}

@mixin border-radius-shadow-2($borderRadius) {
    border-radius: $borderRadius;
    box-shadow: -1.3px 1.5px 19.6px 0.4px rgba(0, 0, 0, 0.1);
}

@mixin border($borderRadius, $size, $color) {
    border-radius: $borderRadius;
    border: solid $size $color;
}

@mixin cursorAnimation() {
    animation-duration: .8s;
    animation-iteration-count: infinite;
    animation-name: cursor;
    animation-timing-function: linear;

    @keyframes cursor {
        0%   { opacity: 1; }
        50%  { opacity: 0; }
        100% { opacity: 1; }
    }
}

@mixin flex($direction, $justify, $align) {
    display: flex;
    flex-direction: $direction;

    @if $justify {
        justify-content: $justify;
    }
    @if $align {
        align-items: $align;
    }
}